'use strict';var _createClass=function(){function defineProperties(target,props){for(var i=0;i<props.length;i++){var descriptor=props[i];descriptor.enumerable=descriptor.enumerable||false;descriptor.configurable=true;if("value"in descriptor)descriptor.writable=true;Object.defineProperty(target,descriptor.key,descriptor);}}return function(Constructor,protoProps,staticProps){if(protoProps)defineProperties(Constructor.prototype,protoProps);if(staticProps)defineProperties(Constructor,staticProps);return Constructor;};}();function _classCallCheck(instance,Constructor){if(!(instance instanceof Constructor)){throw new TypeError("Cannot call a class as a function");}}!function($){/**
 * Accordion module.
 * @module foundation.accordion
 * @requires foundation.util.keyboard
 * @requires foundation.util.motion
 */var Accordion=function(){/**
   * Creates a new instance of an accordion.
   * @class
   * @fires Accordion#init
   * @param {jQuery} element - jQuery object to make into an accordion.
   * @param {Object} options - a plain object with settings to override the default options.
   */function Accordion(element,options){_classCallCheck(this,Accordion);this.$element=element;this.options=$.extend({},Accordion.defaults,this.$element.data(),options);this._init();Foundation.registerPlugin(this,'Accordion');Foundation.Keyboard.register('Accordion',{'ENTER':'toggle','SPACE':'toggle','ARROW_DOWN':'next','ARROW_UP':'previous'});}/**
   * Initializes the accordion by animating the preset active pane(s).
   * @private
   */_createClass(Accordion,[{key:'_init',value:function _init(){this.$element.attr('role','tablist');this.$tabs=this.$element.children('li');if(this.$tabs.length===0){this.$tabs=this.$element.children('[data-accordion-item]');}this.$tabs.each(function(idx,el){var $el=$(el),$content=$el.find('[data-tab-content]'),id=$content[0].id||Foundation.GetYoDigits(6,'accordion'),linkId=el.id||id+'-label';$el.find('a:first').attr({'aria-controls':id,'role':'tab','id':linkId,'aria-expanded':false,'aria-selected':false});$content.attr({'role':'tabpanel','aria-labelledby':linkId,'aria-hidden':true,'id':id});});var $initActive=this.$element.find('.is-active').children('[data-tab-content]');if($initActive.length){this.down($initActive,true);}this._events();}/**
   * Adds event handlers for items within the accordion.
   * @private
   */},{key:'_events',value:function _events(){var _this=this;this.$tabs.each(function(){var $elem=$(this);var $tabContent=$elem.children('[data-tab-content]');if($tabContent.length){$elem.children('a').off('click.zf.accordion keydown.zf.accordion').on('click.zf.accordion',function(e){// $(this).children('a').on('click.zf.accordion', function(e) {
e.preventDefault();if($elem.hasClass('is-active')){if(_this.options.allowAllClosed||$elem.siblings().hasClass('is-active')){_this.up($tabContent);}}else{_this.down($tabContent);}}).on('keydown.zf.accordion',function(e){Foundation.Keyboard.handleKey(e,'Accordion',{toggle:function toggle(){_this.toggle($tabContent);},next:function next(){$elem.next().find('a').focus().trigger('click.zf.accordion');},previous:function previous(){$elem.prev().find('a').focus().trigger('click.zf.accordion');},handled:function handled(){e.preventDefault();e.stopPropagation();}});});}});}/**
   * Toggles the selected content pane's open/close state.
   * @param {jQuery} $target - jQuery object of the pane to toggle.
   * @function
   */},{key:'toggle',value:function toggle($target){if($target.parent().hasClass('is-active')){if(this.options.allowAllClosed||$target.parent().siblings().hasClass('is-active')){this.up($target);}else{return;}}else{this.down($target);}}/**
   * Opens the accordion tab defined by `$target`.
   * @param {jQuery} $target - Accordion pane to open.
   * @param {Boolean} firstTime - flag to determine if reflow should happen.
   * @fires Accordion#down
   * @function
   */},{key:'down',value:function down($target,firstTime){var _this=this;if(!this.options.multiExpand&&!firstTime){var $currentActive=this.$element.find('.is-active').children('[data-tab-content]');if($currentActive.length){this.up($currentActive);}}$target.attr('aria-hidden',false).parent('[data-tab-content]').addBack().parent().addClass('is-active');// Foundation.Move(_this.options.slideSpeed, $target, function(){
$target.slideDown(_this.options.slideSpeed,function(){/**
         * Fires when the tab is done opening.
         * @event Accordion#down
         */_this.$element.trigger('down.zf.accordion',[$target]);});// });
// if(!firstTime){
//   Foundation._reflow(this.$element.attr('data-accordion'));
// }
$('#'+$target.attr('aria-labelledby')).attr({'aria-expanded':true,'aria-selected':true});}/**
   * Closes the tab defined by `$target`.
   * @param {jQuery} $target - Accordion tab to close.
   * @fires Accordion#up
   * @function
   */},{key:'up',value:function up($target){var $aunts=$target.parent().siblings(),_this=this;var canClose=this.options.multiExpand?$aunts.hasClass('is-active'):$target.parent().hasClass('is-active');if(!this.options.allowAllClosed&&!canClose){return;}// Foundation.Move(this.options.slideSpeed, $target, function(){
$target.slideUp(_this.options.slideSpeed,function(){/**
         * Fires when the tab is done collapsing up.
         * @event Accordion#up
         */_this.$element.trigger('up.zf.accordion',[$target]);});// });
$target.attr('aria-hidden',true).parent().removeClass('is-active');$('#'+$target.attr('aria-labelledby')).attr({'aria-expanded':false,'aria-selected':false});}/**
   * Destroys an instance of an accordion.
   * @fires Accordion#destroyed
   * @function
   */},{key:'destroy',value:function destroy(){this.$element.find('[data-tab-content]').slideUp(0).css('display','');this.$element.find('a').off('.zf.accordion');Foundation.unregisterPlugin(this);}}]);return Accordion;}();Accordion.defaults={/**
   * Amount of time to animate the opening of an accordion pane.
   * @option
   * @example 250
   */slideSpeed:250,/**
   * Allow the accordion to have multiple open panes.
   * @option
   * @example false
   */multiExpand:false,/**
   * Allow the accordion to close all panes.
   * @option
   * @example false
   */allowAllClosed:false};// Window exports
Foundation.plugin(Accordion,'Accordion');}(jQuery);